import React from "react";
import s from "./Deliveries.module.scss"
import {Logo} from "../Logo/Logo";
import {Title} from "../common/components/Title/Title";


export function Deliveries() {
    return (
        <div className={s.deliveriesBlock}>
            <Logo/>
            <Title title={"Наши поставки"}/>
            <h2 className={s.deliveries} > Наша компания предлагает к поставкам следующие виды нефепродуктов высокого качества:
                <br/>
                <br/>
                • Дизельное топливо (летнее, зимнее)
                <br/>
                • Мазуты все марок
                <br/>
                • Бензины: Регуляр-92, Нормаль-80
                <br/>
                • Конденсат газовый стабильный
                <br/>
                • Бензин газовый стабильный
                <br/>
                • Топливо самолетное ТС-1, РТ.
            </h2>
        </div>
    )
}