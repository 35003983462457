import React from "react";
import s from "./About.module.scss"
import {Logo} from "../Logo/Logo";
import {Title} from "../common/components/Title/Title";


export function About(){
    return(
        <div className={s.aboutBlock}>
                <Logo/>
            <Title title={"О компании"}/>
                <h2 className={s.about}>
                ООО «Топливные ресурсы» работает на рынке нефтепродуктов по оптовым продажам.
                <br/>
                Общество имеет аккредитацию в секции «Нефтепродукты» Санкт-Петербургской международной товарно-сырьевой биржи.
                <br/>
                Компания имеет успешный опыт сотрудничества с крупными нефтяными компаниями, с которыми заключены прямые договора поставок:
                <br/>
                - группа компаний  Газпром нефть
                <br/>
                - ООО «АНТК»
                <br/>
                - группа компаний Лукойл
                <br/>
                - группа компаний Сокар
                <br/>
                - группа компаний Роснефть
                <br/>
                <br/>
                Наша компания имеет широкую географию поставок:
                <br/>
                Приморье, Забайкальский край, Бурятия, Тува, ЯНАО, ХМАО, Крым, Краснодарский край, Ленинградская область и др.
                <br/>
                Нашими покупателями являются сети АЗС, золотодобывающие предприятия, бункеровочные компании, предприятия дорожного и гражданского строительства и др.
                <br/>
                За годы безупречной деятельности мы завоевали доверие клиентов и партнеров со всей России.
                <br/>
                Мы всегда добросовестно выполняем условия по поставкам, деловая репутация ООО «Топливные ресурсы» безупречна.
                <br/>
                Заключив договор на поставку нефтепродуктов с нашей компанией, вы получите честного, надежного и проверенного временем партнера!</h2>
            </div>
    )
}