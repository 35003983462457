import React from "react";
import s from "./Contacts.module.scss"
import {Logo} from "../Logo/Logo";
import {Button} from "../common/components/Button/Button";
import {Title} from "../common/components/Title/Title";
import { IoIosMail, IoIosCall } from 'react-icons/io';
import { MdOutlineMapsHomeWork} from 'react-icons/md';



export function Contacts(){
    return(
        <div className={s.contactsBlock}>
            <Logo/>
            <Title title={"Контакты"}/>
            <h2 className={s.contacts}>
                <p>Директор ООО «Топливыне ресурсы» Батищев Олег Александрович</p>
                <p><MdOutlineMapsHomeWork color={"#ffbf00"}/> 644011,область Омская город Омск улица 1-я рыбачья дом 35А,кабинет 2</p>
                <p><IoIosCall color={"#ffbf00"}/> +7(983)112-45-25</p>
                <p><IoIosMail color={"#ffbf00"}/> <a href="mailto:toplivnyy.resursy@mail.ru">toplivnyy.resursy@mail.ru</a></p>
            </h2>
           <Button title={"Написать нам"} a ={"mailto:toplivnyy.resursy@mail.ru"}/>
        </div>
    )
}