import React from "react";
import s from "./PageNot.module.scss"


export function PageNot(){
    return(
        <div className={s.pageNot}>
            <h1>404</h1>
            <h1>Oops! Page not found!</h1>
        </div>
    )
}