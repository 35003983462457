import React from "react";
import s from './Header.module.scss'
import {Nav} from "../Nav/Nav";
import {LogoCompany} from "../Logo/LogoCompany";




export function Header() {
    return (
        <header className={s.header}>
            <div className={s.container}>
                <LogoCompany/>
                <Nav />
            </div>
            <img src="https://www.rabstol.net/uploads/gallery/main/181/rabstol_net_abstract_36.jpg" className={s.headerImg} />
        </header>
    );
}
