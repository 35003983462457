import './App.css';
import {Header} from "./components/Header/Header";
import {About} from "./components/About/About";
import {Deliveries} from "./components/Deliveries/Deliveries";
import {Contacts} from "./components/Contacsts/Contacts";
import {Navigate, Route, Routes} from "react-router-dom";
import {PageNot} from "./components/PageNot/PageNot";
import {Content} from "./components/Content/Content";

function App() {
    return (
        <div className="App-wrapper">
            <Header/>
            <div className="App-wrapper-content">
                <Routes>
                    <Route path="/" element={<Content/>}/>
                    <Route path="about" element={<About/>}/>
                    <Route path="deliveries" element={<Deliveries/>}/>
                    <Route path="contacts" element={<Contacts/>}/>
                    <Route path="404" element={<PageNot/>}/>
                    <Route path="*" element={<Navigate to={"404"}/>}/>
                </Routes>
            </div>
        </div>
    );
}
export default App;