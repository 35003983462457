import React from "react";
import s from "./Nav.module.scss"
import {NavLink} from "react-router-dom";



export function Nav(){
    return(
        <div className={s.nav}>
                <NavLink to={"/"} style={({ isActive }) => ({ color: isActive ? '#ffbf00' : '#2B2000' })}>Главная</NavLink >
                <NavLink to={"about"} style={({ isActive }) => ({ color: isActive ? '#ffbf00' : '#2B2000' })}>О компании</NavLink >
                <NavLink to={"deliveries"} style={({ isActive }) => ({ color: isActive ? '#ffbf00' : '#2B2000' })}>Поставки</NavLink >
                <NavLink to={"contacts"} style={({ isActive }) => ({ color: isActive ? '#ffbf00' : '#2B2000' })}>Контакты</NavLink >
        </div>
    )
}