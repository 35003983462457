import React from "react";
import {About} from "../About/About";
import {Deliveries} from "../Deliveries/Deliveries";
import {Contacts} from "../Contacsts/Contacts";


export function Content(){
    return(
     <>
         <About/>
         <Deliveries/>
         <Contacts/>
     </>
    )
}