import React from "react";
import s from "./LogoCompany.module.scss"
import {Logo} from "./Logo";



export function LogoCompany(){
    return(
            <div className={s.logoCompany}>
                <Logo/><h2 className={s.logoText}>Топливные ресурсы</h2>
            </div>
    )
}